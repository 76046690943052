@import '../config';

/*
Table of contents:
- GENERAL CSS
- fullscreen-menu overlay
- hamburger-icon
*/
/*----------------------------------*/
/* GENERAL */

body.hamburger-open {
	//overflow: hidden;
}

/* GENERAL */
/*----------------------------------*/
/*----------------------------------*/
/* fullscreen-menu overlay */
#overlay {
	display: none;
	position: absolute;
	left: 0;
	top: 0; // header height
	width: 100%;
	height: 100%;
	background-color: $hamburger-overlay-color;
	z-index: 1000;
	@media screen and (max-width: $hamburger-breakpoint) {
		opacity: 1;
	}
}

#overlay .header__navigation--mobile {
	display: flex;
	width: 100%;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

/* fullscreen-menu */
/*----------------------------------*/
/*----------------------------------*/
/* hamburger-icon  */
.hamburger {
	display: none;
	align-items: center;
	position: relative;
	z-index: 999;
	height: $hamburger-height;
	cursor: pointer;
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
	@media screen and (max-width: $hamburger-breakpoint) {
		display: flex;
	}

	.hamburger__label {
		margin-right: 1rem;
		color: $hamburger-label-color;
	}

	.hamburger__trigger {
		width: $hamburger-line-width;
	}

	.hamburger__trigger--bread-top,
	.hamburger__trigger--meat,
	.hamburger__trigger--bread-bottom {
		position: absolute;
		display: block;
		width: $hamburger-line-width;
		height: $hamburger-line-height;
		opacity: 1;
		right: 0;
		border-radius: $hamburger-line-radius;
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: .25s ease-in-out;
		transition: .25s ease-in-out;
		background-color: $hamburger-line-color;
	}
}

.hamburger .hamburger__trigger--bread-top {
	top: 0;
}

.hamburger .hamburger__trigger--meat {
	top: $hamburger-line-height + $hamburger-line-gap;
}

.hamburger .hamburger__trigger--bread-bottom {
	top: ($hamburger-line-height + $hamburger-line-gap) * 2;
}

.hamburger.animate-icon .hamburger__trigger--bread-top {
	top: calc($hamburger-height / 2);
	-webkit-transform: rotate(135deg);
	transform: rotate(135deg);
}

.hamburger.animate-icon .hamburger__trigger--meat {
	opacity: 0;
}

.hamburger.animate-icon .hamburger__trigger--bread-bottom {
	top: calc($hamburger-height / 2);
	-webkit-transform: rotate(-135deg);
	transform: rotate(-135deg);
}
